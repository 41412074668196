import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from 'projects/shared-resources/src/lib/services/storage.service';
import { TokenKey } from 'projects/shared-resources/src/lib/services/auth.service';
import { API_CONFIG } from 'projects/shared-resources/src/lib/services/api-utils.service';
import { BackendRoute } from 'projects/shared-resources/src/lib/enums/api';

@Injectable()
export class ModifiedRequestInterceptor implements HttpInterceptor {
  constructor(
    private storageService: StorageService,
    @Inject(API_CONFIG) private apiConfig: { apiRoot: string },
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const apiPath = `${this.apiConfig.apiRoot}/${BackendRoute.Api}`;
    const isThirdPartSource = /^http/.exec(request.url);
    if (isThirdPartSource) return next.handle(request);
    const accessToken = this.storageService.get(TokenKey.AccessToken);

    return next.handle(
      request.clone({
        url: apiPath + request.url,
        headers: accessToken
          ? request.headers.set('Authorization', `Bearer ${accessToken}`)
          : request.headers,
      }),
    );
  }
}
