import { AuthService } from 'projects/shared-resources/src/lib/services/auth.service';
import { AppRoutePath } from 'projects/vacation-cms/src/app/app-routes';
import { RoutePath } from 'projects/vacation-cms/src/app/ui/enums';
import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';

export const authGuard: CanActivateChildFn = () => {
  const authService = inject(AuthService);

  if (authService.isLoggedOut()) {
    const router = inject(Router);
    router.navigate([AppRoutePath.Cms, RoutePath.Login]);
    return false;
  }
  return true;
};
