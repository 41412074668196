import { Routes } from '@angular/router';
import cmsRoutes from './ui/cms-routes';

export enum AppRoutePath {
  Cms = 'cms',
}

export const appRoot: Routes = [
  {
    path: AppRoutePath.Cms,
    loadComponent: () =>
      import('./ui/cms.component').then((mod) => mod.default),
    children: cmsRoutes,
  },
  {
    path: '**',
    redirectTo: AppRoutePath.Cms,
  },
];
