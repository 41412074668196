import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { appRoot } from './app-routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptors } from 'projects/shared-resources/src/lib/interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from 'projects/vacation-cms/src/environments/environment';
import { MatDialogModule } from '@angular/material/dialog';
import { VACATION_THEME } from '../theme';
import {
  API_CONFIG,
  AZURE_CONFIG,
  THEME_CONFIG,
} from 'projects/shared-resources/src/public-api';
import { TranslateModule } from '@ngx-translate/core';
import { TRANSLATE_CONFIG } from 'projects/shared-resources/src/lib/services/translate.service';
import { WEBSITE_CODES } from '../environments/model';
import { generateMockDataAppConfig } from 'projects/shared-resources/src/lib/interceptor/mockData.interceptor';

import { MOCK_API_DATA } from './constants/mockApi.constant';
import { IS_PRODUCTION } from 'projects/shared-resources/src/lib/tokens';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: IS_PRODUCTION,
      useValue: environment.production,
    },
    {
      provide: API_CONFIG,
      useValue: {
        apiRoot: `${environment.baseUrl}/${environment.websiteCode}`,
        isMockEnabled: environment.isMockEnabled,
      },
    },
    {
      provide: THEME_CONFIG,
      useValue: VACATION_THEME,
    },
    {
      provide: AZURE_CONFIG,
      useValue: {
        azureUrl: environment.azureUrl,
        azureClientId: environment.azureClientId,
        azureLoginUserFlow: environment.azureLoginUserFlow,
        azureResetPasswordUserFlow: environment.azureResetPasswordUserFlow,
        azureTwoFactorUserFlow: environment.azureTwoFactorUserFlow,
      },
    },
    {
      provide: TRANSLATE_CONFIG,
      useValue: {
        currentToken: environment.websiteCode,
        tokenList: WEBSITE_CODES,
      },
    },
    provideRouter(
      appRoot,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      }),
    ),
    importProvidersFrom(
      BrowserAnimationsModule,
      MatSnackBarModule,
      MatDialogModule,
      HttpClientModule,
      TranslateModule.forRoot(),
    ),
    ...(environment.isMockEnabled
      ? generateMockDataAppConfig(MOCK_API_DATA)
      : []),
    ...httpInterceptors,
  ],
};
