import { COLORS } from '../style/colors.constant';
import { Colors } from '../style/colors.model';

export default {
  primary: COLORS['ocean-blue'],
  secondary: COLORS['night-sky-dark-blue-100'],
  tertiary: COLORS['ocean-blue-100'],
  'tertiary-layer': COLORS['morning-orange-300'],
  'tertiary-selector': COLORS['ocean-blue-100'],
  'tertiary-card': COLORS['beach-sand-20'],
  'tertiary-a-hover': COLORS['sunny-yellow'],
  'tertiary-hr': COLORS['night-sky-dark-blue-8'],
  'tertiary-ghost-input': COLORS['beach-sand-20'],
  'tertiary-ghost-input-suffix': COLORS['morning-orange'],
  neutral: COLORS['sunny-yellow-400'],
  background: COLORS['beach-sand-500'],
  loader: COLORS['ocean-blue'],
} as Colors;
