import { inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { tap, share, finalize } from 'rxjs/operators';
import { ApiCacheService } from '../services/api-cache.service';
import { IS_PRODUCTION } from '../tokens';

@Injectable()
export class CacheGetInterceptor implements HttpInterceptor {
  private inFlightRequests = new Map<
    string,
    Subject<HttpResponse<unknown>>
  >();
  private apiCacheService = inject(ApiCacheService);
  private isProd = inject(IS_PRODUCTION);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (req.method !== 'GET') {
      return next.handle(req);
    }

    const cachedResponse = this.apiCacheService.get(req.urlWithParams);
    if (cachedResponse) {
      const res = cachedResponse.clone();
      if (!this.isProd) {
        console.warn('cached response:', res);
      }
      return of(res);
    }

    if (this.inFlightRequests.has(req.urlWithParams)) {
      return this.inFlightRequests.get(req.urlWithParams)!.asObservable();
    } else {
      const subject = new Subject<HttpResponse<unknown>>();
      this.inFlightRequests.set(req.urlWithParams, subject);

      next
        .handle(req)
        .pipe(
          tap((event) => {
            if (event instanceof HttpResponse) {
              this.apiCacheService.set(req.urlWithParams, event.clone());
              subject.next(event.clone());
              subject.complete();
            }
          }),
          finalize(() => {
            this.inFlightRequests.delete(req.urlWithParams);
          }),
          share(),
        )
        .subscribe();

      return subject.asObservable();
    }
  }
}
