import { inject, Injectable, InjectionToken } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

export type MockData = Record<
  string,
  unknown | ((url: string) => unknown)
>;

const MOCK_DATA = new InjectionToken<MockData>(
  '[Mock Data Interceptor] mock data',
);

@Injectable()
class MockDataInterceptor implements HttpInterceptor {
  private mockData = inject(MOCK_DATA);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const { url } = req;

    for (const urlPattern of Object.keys(this.mockData)) {
      const regex = new RegExp(urlPattern);
      if (regex.test(url)) {
        const dataBuilder = this.mockData[urlPattern];
        const mockResponse =
          typeof dataBuilder === 'function'
            ? dataBuilder(url)
            : dataBuilder;
        console.warn(`Mocking response for: ${url}`, mockResponse);

        return of(
          new HttpResponse({
            status: 200,
            body: mockResponse,
          }),
        ).pipe(delay(300));
      }
    }
    return next.handle(req);
  }
}

export const generateMockDataAppConfig = (mockData: MockData) => [
  {
    provide: MOCK_DATA,
    useValue: mockData,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MockDataInterceptor,
    multi: true,
  },
];
