import { Injectable, InjectionToken, inject } from '@angular/core';
import { Location } from '@angular/common';

export const AZURE_CONFIG = new InjectionToken<AzureConfig>('[Travel] azure config');

export interface AzureConfig {
  azureUrl: string;
  azureClientId: string;
  azureLoginUserFlow: string;
  azureResetPasswordUserFlow: string;
  azureTwoFactorUserFlow:string;
}

@Injectable({
  providedIn: 'root',
})
export class AzuresService {
  private location = inject(Location);
  private azureConfig = inject(AZURE_CONFIG)

  private get currentUri() {
    return encodeURI(`${window.location.origin}${this.location.path()}`);
  }

  public get clientId() {
    return this.azureConfig.azureClientId;
  }

  public get resetPasswordUrl() {
    return `${this.azureConfig.azureUrl}/authorize?p=${this.azureConfig.azureResetPasswordUserFlow}&client_id=${this.clientId}&nonce=defaultNonce&redirect_uri=${this.currentUri}&scope=openid&response_type=id_token&prompt=login`;
  }

  public get tokenUrl() {
    return `${this.azureConfig.azureUrl}/token?p=${this.azureConfig.azureLoginUserFlow}`;
  }

  public getTwoFactorAuthUrl(email: string) {
    const encodedEmail = encodeURIComponent(email);
    return `${this.azureConfig.azureUrl}/authorize?p=${this.azureConfig.azureTwoFactorUserFlow}&client_id=${this.clientId}&nonce=defaultNonce&redirect_uri=${this.currentUri}&scope=openid&response_type=id_token&prompt=login&memberEmail=${encodedEmail}`;
  }
}
