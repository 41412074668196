import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  standalone: true,
  selector: 'loading-dialog',
  template: `<mat-spinner class="root-loader !h-16 !w-16"></mat-spinner>`,
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class LoadingDialogComponent {}
