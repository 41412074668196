/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, afterNextRender } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  _window = {
    localStorage: {
      setItem: (_key: string, _value: string) => {},
      getItem: (_key: string) => '',
      removeItem: (_key: string) => {},
      clear: () => {},
    },
  } as unknown as Window;

  constructor() {
    afterNextRender(() => {
      this._window = window;
    });
  }

  set(key: string, value: unknown) {
    this._window.localStorage.setItem(key, JSON.stringify(value));
  }

  get(key: string) {
    const data = this._window.localStorage.getItem(key);
    return data ? JSON.parse(data) : undefined;
  }

  remove(key: string) {
    this._window.localStorage.removeItem(key);
  }

  clear() {
    this._window.localStorage.clear();
  }
}
