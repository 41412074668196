import {
  Injectable,
  InjectionToken,
  afterNextRender,
  inject,
} from '@angular/core';
import type {
  BaseThemeConfig,
  CSSColor,
} from '../../../style/colors.model';
import { THEME_COLORS_LIST } from '../../../style/colors.constant';
import { generateColorVariableName } from './utils';

export const THEME_CONFIG = new InjectionToken<BaseThemeConfig>(
  '[Vacation] theme config',
);
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private THEME = inject(THEME_CONFIG);

  public init() {
    afterNextRender(() => {
      this.updateScss();
      this.updateFavicon();
    });
  }

  private updateScss() {
    const colors = this.THEME.COLORS;
    THEME_COLORS_LIST.forEach((colorName) => {
      const variableName = generateColorVariableName(colorName);
      document.documentElement.style.setProperty(
        variableName,
        colors[colorName],
      );
      document.documentElement.style.setProperty(
        variableName + '-hex',
        this.rgbToHex(colors[colorName]),
      );
    });
  }

  private rgbToHex(color: CSSColor) {
    const rgbArray = color.split(' ');
    return (
      '#' +
      rgbArray
        .map((x) => {
          const hex = (+x).toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        })
        .join('')
    );
  }

  private updateFavicon() {
    const favIcon = document.querySelector(
      "link[rel~='icon']",
    ) as HTMLLinkElement;
    favIcon.href = this.THEME.FAV_ICON;
  }
}
