import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, TokenKey } from 'projects/shared-resources/src/lib/services/auth.service';
import { LoaderService } from 'projects/shared-resources/src/lib/services/loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private loader: LoaderService,
  ) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(catchError((error) => this.handleError(request, next, error)));
  }

  private handleError(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    error: unknown,
  ) {
    if (!(error instanceof HttpErrorResponse))
      return throwError(() => error);

    this.loader.reset();
    switch (error.status) {
      case 400:
      case 409:
        return throwError(() => error);
      case 401:
        return this.handle401Error(request, next);
      default:
        return this.handleUnexpectedError(error);
    }
  }

  private handleUnexpectedError(error: HttpErrorResponse) {
    this.snackBar.open(error.message, '', { duration: 5000 });
    return throwError(() => error);
  }

  private handle401Error(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (
      this.authService.isLoggedOut() &&
      this.authService.isRefreshToken()
    ) {
      return this.authService.refreshAccessToken().pipe(
        switchMap((authTokens) => {
          const accessToken = authTokens[TokenKey.AccessToken];
          const headers = request.headers.append(
            'Authorization',
            `Bearer ${accessToken}`,
          );
          return next.handle(request.clone({ headers }));
        }),
        catchError((error) => {
          if (error.status === 401) {
            this.authService.logout();
            return throwError(() => error);
          }
          return this.handleError(request, next, error);
        }),
      );
    }
    return next.handle(request);
  }
}
