import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { register } from 'swiper/element/bundle';
import { LoaderService } from 'projects/shared-resources/src/lib/services/loader.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { AuthService } from 'projects/shared-resources/src/lib/services/auth.service';
import { ThemeService } from 'projects/shared-resources/src/lib/services/theme/theme.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingDialogComponent } from 'projects/shared-resources/src/public-api';
import { THEME_CONFIG } from 'projects/shared-resources/src/lib/services/theme/theme.service';
import { TranslateService } from 'projects/shared-resources/src/lib/services/translate.service';

@Component({
  standalone: true,
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  imports: [CommonModule, RouterModule],
  providers: [TranslateService],
})
export default class AppComponent implements OnInit {
  private loader = inject(LoaderService);
  private dialog = inject(MatDialog);
  private authService = inject(AuthService);
  private cdr = inject(ChangeDetectorRef);
  private themeService = inject(ThemeService);
  private THEME = inject(THEME_CONFIG);

  title = this.THEME.NAME;

  isLoading$ = toObservable(this.loader.isLoading);
  loaderRef?: MatDialogRef<LoadingDialogComponent>;

  isLoggedIn$ = toObservable(this.authService.isLoggedIn);

  constructor() {
    this.themeService.init();
  }

  ngOnInit(): void {
    this.isLoading$.subscribe((isLoading) => {
      if (isLoading) {
        this.loaderRef = this.dialog.open(LoadingDialogComponent, {
          panelClass:'root-loader-panel',
          disableClose: true,
          exitAnimationDuration: 200,
        });
      } else if (this.loaderRef) {
        this.loaderRef.close();
      }
    });

    this.isLoggedIn$.subscribe(() => {
      this.cdr.detectChanges();
    });

    /** {@link https://stackoverflow.com/questions/75326426/how-to-use-swiper-9-with-angular}*/
    register();
  }
}
