import { Injectable, computed, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private sequence = new Set();
  private sequenceCount = signal(0);
  isLoading = computed(() => this.sequenceCount() !== 0);

  start(key?: string | number) {
    const eventKey = `${key ?? ''}-${new Date().toISOString()}`;
    this.sequence.add(eventKey);
    this.sequenceCount.set(this.sequence.size);
    return {
      eventKey,
      complete: () => this.complete(eventKey),
    };
  }

  complete(eventKey: string) {
    this.sequence.delete(eventKey);
    this.sequenceCount.set(this.sequence.size);
  }

  reset() {
    this.sequence.clear();
    this.sequenceCount.set(0);
  }
}
