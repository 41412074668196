// import { cmsBlogTypes } from '../ui/pages/blog/spec-helper/blog-type.spec-helper';
// import { cmsBlog } from '../ui/pages/blog/spec-helper/blog-detail.spec-helper';
// import { cmsBlogList } from '../ui/pages/blog/spec-helper/blog-list.spec-helper';
// import {
//   cmsFaqCategory,
//   cmsFaqDetail,
//   cmsFaqList,
// } from '../ui/pages/frequently-ask-question/spec-helper';
// import { cmsCarousel } from '../ui/pages/homepage-carousel/spec-helper/carousel-list.spec-helper';
// import { cmsAuthProfile } from '../ui/services/user/user.spec-helper';
// import { HOMEPAGE_BLOG_TYPE } from '../ui/pages/blog/spec-helper/feature-blog-type.spec-helper';
// import { PROPERTIES } from 'projects/vacation-forestage/src/app/ui/pages/home-page/components/properties-explore/spec-helper/properties.spec-helper';
// import { CMS_HOTEL_OFFER_LIST } from '../ui/pages/hotel-offer/spec-helper/hotel-offer-list.spec-helper';
// import { CMS_HOTEL_OFFER } from '../ui/pages/hotel-offer/spec-helper/hotel-offer.spec-helper';

export const MOCK_API_DATA = {
  // '/cms/auth/profile': cmsAuthProfile,
  // '/cms/carousel$': cmsCarousel,
  // '/cms/carousel/\\d+$': (url: string) => {
  //   const paramId = url.split('/').pop();
  //   return cmsCarousel.data.find(({ id }) => paramId && id === +paramId);
  // },
  // '/cms/faq$': cmsFaqList,
  // '/cms/faq/\\d+$': cmsFaqDetail,
  // '/cms/faq/category$': cmsFaqCategory,
  // '/cms/blog$': cmsBlogList,
  // '/cms/blog/id/\\d+$': cmsBlog,
  // '/cms/blog/type$': cmsBlogTypes,
  // '/blog/type/homepage$': HOMEPAGE_BLOG_TYPE,
  // '/property$': PROPERTIES,
  // '/cms/hoteloffer$': CMS_HOTEL_OFFER_LIST,
  // '/cms/hoteloffer/\\d+$': CMS_HOTEL_OFFER,
};
