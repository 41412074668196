import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  Injectable,
  afterNextRender,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import {
  Subscription,
  catchError,
  lastValueFrom,
  map,
  of,
  switchMap,
  take,
  throwError,
  timer,
} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import add from 'date-fns/add';
import isBefore from 'date-fns/isBefore';

import { LoaderService } from './loader.service';
import { StorageService } from './storage.service';
import { AzuresService } from './azure.service';

const CONFIRM_MESSAGE =
  'Please confirm that your email/password is correct';

interface LoginPayload {
  email: string;
  password: string;
}

export enum TokenKey {
  TokenType = 'token_type',
  AccessToken = 'access_token',
  ExpiresIn = 'expires_in',
  RefreshToken = 'refresh_token',
  IdToken = 'id_token',
}

interface AuthRes {
  [TokenKey.TokenType]: 'Bearer';
  [TokenKey.AccessToken]: string;
  [TokenKey.RefreshToken]: string;
  [TokenKey.ExpiresIn]: string | number;
  [TokenKey.IdToken]: string;
}

const EXPIRES_AT_KEY = 'expiresAt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private http = inject(HttpClient);
  private snackBar = inject(MatSnackBar);
  private loader = inject(LoaderService);
  private azureService = inject(AzuresService);
  private storageService = inject(StorageService);

  public expiresAt = signal<Date | null>(null);
  public isLoggedIn = computed(() => {
    const expiresAt = this.expiresAt();
    if (!expiresAt) return false;
    return isBefore(new Date(), new Date(expiresAt));
  });
  public isLoggedOut = computed(() => !this.isLoggedIn());

  private currentTimer?: Subscription;

  constructor() {
    afterNextRender(() => {
      const currentExpiresAt: string | undefined =
        this.storageService.get(EXPIRES_AT_KEY);
      if (currentExpiresAt) {
        this.expiresAt.set(new Date(currentExpiresAt));
      }
    });

    effect(() => {
      const expiresAt = this.expiresAt();
      if (expiresAt) {
        this.currentTimer = this.expirationTimer(expiresAt).subscribe();
      } else {
        this.currentTimer?.unsubscribe();
      }
    });
  }

  public async login({ email, password }: LoginPayload) {
    const loader = this.loader.start();
    const body = this.createFormData({
      username: email,
      password: password,
      grant_type: 'password',
    });
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    try {
      const res = await lastValueFrom(
        this.http.post<AuthRes>(this.azureService.tokenUrl, body, {
          headers,
        }),
      );
      this.setSession(res);
      loader.complete();
    } catch (error: unknown) {
      const { status } = error as HttpErrorResponse;
      if (status === 400) {
        this.snackBar.open(CONFIRM_MESSAGE, '', { duration: 3000 });
      }
      loader.complete();
      throw error;
    }
  }

  public isRefreshToken() {
    return !!this.storageService.get(TokenKey.RefreshToken);
  }

  public refreshAccessToken() {
    const refreshToken: string | undefined = this.storageService.get(
      TokenKey.RefreshToken,
    );
    const loader = this.loader.start();
    return of(refreshToken).pipe(
      take(1),
      switchMap((refreshToken) => {
        if (!refreshToken)
          return throwError(() => new Error('refresh token missing'));
        const body = this.createFormData({
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        });
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<AuthRes>(this.azureService.tokenUrl, body, {
          headers,
        });
      }),
      map((authRes) => {
        loader.complete();
        this.setSession(authRes);
        return authRes;
      }),
      catchError((error: HttpErrorResponse) => {
        loader.complete();
        return throwError(() => error);
      }),
    );
  }
  private createFormData(payload: Record<string, string>) {
    const body = new FormData();
    body.append(
      'scope',
      `openid ${this.azureService.clientId} offline_access`,
    );
    body.append('client_id', this.azureService.clientId);
    body.append('response_type', 'code id_token token');
    Object.keys(payload).forEach((key) => body.append(key, payload[key]));
    return body;
  }

  public setSession(session: AuthRes) {
    const {
      [TokenKey.AccessToken]: accessToken,
      [TokenKey.RefreshToken]: refreshToken,
      [TokenKey.IdToken]: idToken,
      [TokenKey.ExpiresIn]: expiresIn,
    } = session;

    const expiresAt = add(new Date(), {
      seconds: +expiresIn,
    });
    this.storageService.set(TokenKey.AccessToken, accessToken);
    this.storageService.set(TokenKey.RefreshToken, refreshToken);
    this.storageService.set(TokenKey.IdToken, idToken);
    this.storageService.set(EXPIRES_AT_KEY, expiresAt.toISOString());
    this.expiresAt.set(expiresAt);
  }

  public logout() {
    this.storageService.remove(TokenKey.AccessToken);
    this.storageService.remove(TokenKey.RefreshToken);
    this.storageService.remove(TokenKey.IdToken);
    this.storageService.remove(EXPIRES_AT_KEY);
    this.expiresAt.set(null);
  }

  private expirationTimer(secondsFromNow: number | Date) {
    return timer(secondsFromNow).pipe(
      take(1),
      switchMap(() => this.refreshAccessToken()),
      catchError((error) => {
        this.logout();
        return throwError(() => error);
      }),
    );
  }
}
