import { ThemeConfig } from './theme-config.model';
import COLOR_CONFIG from 'projects/shared-resources/src/custom-config/vacation-color-config';

export const THEME: ThemeConfig = {
  NAME: 'Yes&Vacations',
  FAV_ICON:
    'projects/shared-resources/src/assets/favicon/yesAndTravel.ico',
  LOGO: 'projects/shared-resources/src/assets/img/png/vacationLogo.png',
  COLORS: COLOR_CONFIG,
};
