import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface CacheEntry {
  response: HttpResponse<unknown>;
  lastUpdated: number;
}

@Injectable({
  providedIn: 'root',
})
export class ApiCacheService {
  private cache = new Map<string, CacheEntry>();
  private maxAge = 1000; // Cache duration in milliseconds (e.g., 1 second)

  get(key: string): HttpResponse<unknown> | undefined {
    const entry = this.cache.get(key);
    if (!entry) {
      return undefined;
    }

    const isExpired = Date.now() - entry.lastUpdated > this.maxAge;
    if (isExpired) {
      this.cache.delete(key);
      return undefined;
    }

    return entry.response;
  }

  set(key: string, response: HttpResponse<unknown>): void {
    const entry: CacheEntry = {
      response: response.clone(),
      lastUpdated: Date.now(),
    };
    this.cache.set(key, entry);
  }

  clear(): void {
    this.cache.clear();
  }

  delete(key: string): void {
    this.cache.delete(key);
  }
}
