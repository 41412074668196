import { inject, Injectable, InjectionToken } from '@angular/core';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';

export const TRANSLATE_CONFIG = new InjectionToken<{
  currentToken: string;
  tokenList: string[];
}>('[Travel] translate config');

export interface Locale<
  DATA extends Record<string, unknown> = Record<string, unknown>,
> {
  lang: string;
  data: DATA;
}

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private translateConfig = inject(TRANSLATE_CONFIG);
  private translate = inject(NgxTranslateService);

  constructor() {
    this.translate.addLangs(this.translateConfig.tokenList);
    this.translate.setDefaultLang(this.translateConfig.currentToken);
    this.translate.use(this.translateConfig.currentToken);
  }

  public loadTranslations(...locals: Locale[]): void {
    const locales = [...locals];
    locales.forEach((locale) => {
      this.translate.setTranslation(
        locale.lang,
        locale.data as object,
        true,
      );
    });
  }

  public instant: NgxTranslateService['instant'] = (...params) =>
    this.translate.instant(...params);
}
