export const WEBSITE_CODES = ['YesAndVac' as const];

export type WebsiteCode = (typeof WEBSITE_CODES)[number];

export interface Environment {
  production: boolean;
  /**
   * You have to switch this, once your real back-end is done (used to be true)
   */
  isMockEnabled: boolean;
  /**
   * API endpoint
   */
  baseUrl: string;
  websiteCode: WebsiteCode;

  forestageUrl: string;

  // Azure
  azureUrl: string;
  azureClientId: string;
  azureLoginUserFlow: string;
  azureResetPasswordUserFlow: string;
  azureTwoFactorUserFlow: string;

  // 3rd Party Plugin
}
